import { template as template_ba3d0af01fac4730b181642a7ec5aa8b } from "@ember/template-compiler";
const FKLabel = template_ba3d0af01fac4730b181642a7ec5aa8b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
