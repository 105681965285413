import { template as template_4976bb3a72674ed38c82b4755d0fb08a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4976bb3a72674ed38c82b4755d0fb08a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
