import { template as template_94e31e1a3b2e4fa5a7affdbade85b344 } from "@ember/template-compiler";
const WelcomeHeader = template_94e31e1a3b2e4fa5a7affdbade85b344(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
